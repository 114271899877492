/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:de9185cc-cfa8-4676-a151-709623017316",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_tt4dpT8jo",
    "aws_user_pools_web_client_id": "3dgh33jqnkk5to7kj7oq071n11",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "primeurs2019-documents172205-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "331bb51826ea423f8a4b247d8cac9138",
    "aws_mobile_analytics_app_region": "eu-central-1"
};


export default awsmobile;
